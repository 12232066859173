import React, { useEffect, useState, lazy ,Suspense  } from 'react';
import { HashRouter as Router, Route, Routes,Navigate  } from 'react-router-dom';
import './App.css';
import LoadingSpinner from './components/LoadingSpinner ';

export default function App() {
  const SearchCustomerUI = lazy(() => import('./features/SearchCustomerUI'));
  const CreateCustomer = lazy(() => import('./features/CreateCustomer'));
  const SignInSide = lazy(() => import('./features/SignInSide'));
  const Home = lazy(() => import('./features/Home'));

  const [isLoggedIn, setLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  
  const handleLogin = () => {
    // 設置登入狀態 true，並儲存到 localStorage 中
    setLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };


  const handleLogout = () => {
    // 登出後，設置登入狀態為 false，然後從localStorage中移除
    setLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
  };
  
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn') === 'true';
    setLoggedIn(storedLoginStatus);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Suspense fallback={<LoadingSpinner />} ><SignInSide onLogin={handleLogin} /></Suspense>} />
        {!isLoggedIn ? (
          <Route path="*" element={<Navigate to="/login" />} />
        ) : (
          // 如果已登入，顯示主頁面
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingSpinner />}>
                <Home onLogout={handleLogout} isLoggedIn={isLoggedIn} />
              </Suspense>
            }
          >
            <Route path="search-customer" element={<SearchCustomerUI />} />
            <Route path="create-customer" element={<CreateCustomer />} />
          </Route>
        )
        }
      </Routes>
    </Router>
  );
}